import * as React from "react"

import Seo from "../../components/seo"

const PersonalizedLearningPage = () => {
  return (
    <section id="personalized-learning">
      <Seo
        title="Personalized Learning"
        description="Flexibility in education with extra personalized support is critical to keeping students enrolled and engaged in school, working toward a high school diploma."
      />
      <div className="one half-pic-right">
        <div className="full-screen">
          <div className="left">
            <div className="content">
              <h2>Personalized Learning</h2>
              <p>
                Education must become personalized. The more we can tailor
                curriculum and wrap-around services to the individual student,
                the more they are engaged in their learning. Students should
                progress from grade-to-grade based on their competency in the
                subject matter, not how much time they sit in a classroom.{" "}
              </p>
            </div>
          </div>
          <div className="right"></div>
        </div>
        <div className="mobile">
          <div className="content">
            <h2>Personalized Learning</h2>
            <p>
              Education must become personalized. The more we can tailor
              curriculum and wrap-around services to the individual student, the
              more they are engaged in their learning. Students should progress
              from grade-to-grade based on their competency in the subject
              matter, not how much time they sit in a classroom.{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="two split-half-circle-left">
        <div className="container">
          <div className="content">
            <div className="left">
              <div className="circle-pic"></div>
            </div>
            <div className="right">
              <div className="wrap-content">
                <p>
                  The new reality is a need for flexibility. Schools must be
                  able to offer effective remote and on-site learning without
                  interruption and with personalized support.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="three half-pic-right">
        <div className="full-screen">
          <div className="left">
            <div className="content">
              <p>
                Students who must work or care for children rely on flexibility
                so they can stay in school.
              </p>
            </div>
          </div>
          <div className="right"></div>
        </div>
        <div className="mobile">
          <div className="content">
            <p>
              Students who must work or care for children rely on flexibility so
              they can stay in school.
            </p>
          </div>
        </div>
      </div>
      <div className="four smaller-text-center">
        <div className="container">
          <div className="content">
            <p>
              Trauma-informed practices must become the standard. Schools have
              primarily treated behaviors associated with stress and grief as
              discipline cases rather than opportunities to teach life skills.
            </p>
          </div>
        </div>
      </div>
      <div className="five split-half-circle-right">
        <div className="container">
          <div className="content full-screen">
            <div className="left">
              <div className="wrap-content">
                <p>
                  Students should be empowered to take ownership and
                  responsibility for their present and future learning. For
                  students whose path to graduation is obstructed by hardship,
                  we must provide extra supports to keep them on track.
                </p>
              </div>
            </div>
            <div className="right">
              <div className="circle-pic"></div>
            </div>
          </div>
          <div className="content mobile">
            <div className="wrap-content">
              <p>
                Students should be empowered to take ownership and
                responsibility for their present and future learning. For
                students whose path to graduation is obstructed by hardship, we
                must provide extra supports to keep them on track.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PersonalizedLearningPage
